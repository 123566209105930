<template>
    <!-- 域名备案 -->
    <div class="dmRecord">
        <div class="dmrBox1">
            <div class="dmrTop">
                <img
                    src="https://qcloudimg.tencent-cloud.cn/trisys/assets/product/images/BVMs-JAPP8soP-xKuHO_8.jpg"
                    alt=""
                    class="dtBg"
                />
                <div class="dtT">
                    <p class="dt1">网站备案</p>
                    <p class="dt2">
                        在中国大陆境内提供服务的网站都应依法进行ICP备案和公安备案，威有云为您 免费提供高效便捷<br />
                        的ICP备案服务。
                    </p>
                    <div class="dt3">
                        <div class="dt3B">开始备案</div>
                        <div class="dt3B2">备案管理</div>
                        <div class="dt3B2">备案指南</div>
                    </div>
                </div>
            </div>
            <div class="dmrBottom">
                <div class="db1">
                    <div class="db1Video">
                        <video
                            src="//main.qcloudimg.com/trisys/assets/product/videos/product-video-cover.mov"
                            autoplay
                            loop
                        ></video>
                    </div>
                    <div class="db1Ts">
                        <ul>
                            <li class="dtLi" v-for="item in 3" :key="item">
                                <p class="dtl1">备案场景介绍</p>
                                <p class="dtl2">备案场景介绍，新手指引</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="db2">
                    <div class="db2t" v-for="item in 2" :key="item">
                        <div class="d21I">产品公告</div>
                        <p class="d21P">【2022-01-20】关于做好ICP备案“宗教”前置审批的通知</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="dmrBox2">
            <div class="db2Box">
                <div class="d2bTop">备案流程</div>
                <div class="d2bList">
                    <ul>
                        <li class="d2lLi" v-for="item in 6" :key="item">
                            <div class="d2l1">{{ item }}</div>
                            <p class="d2l2">填写备案信息</p>
                            <p class="d2l3">查看备案材料 <i class="iconfont icon-youbianjiantou"></i></p>
                        </li>
                    </ul>
                    <div class="d2x"></div>
                </div>
            </div>
        </div>
        <div class="dmrBox3">
            <div class="db3Top">
                <p class="d3tp">备案指引</p>
                <div class="d3tTab">
                    <ul>
                        <li
                            class="d3tLi"
                            v-for="item in 3"
                            :key="item"
                            :class="{ d3tActive: isActive === item }"
                            @click="d3tA(item)"
                        >
                            备案基础了解
                        </li>
                    </ul>
                </div>
            </div>
            <div class="db3Bottom">
                <div class="d3bBox" v-show="showD3 === 1">
                    <div class="d3bLeft">
                        <ul>
                            <li
                                class="d3lLi"
                                v-for="item in 5"
                                :key="item"
                                :class="{ d3lActive: isActive2 === item }"
                                @click="d3lA(item)"
                            >
                                <p class="d3lp">基础敏名词定义</p>
                            </li>
                        </ul>
                    </div>
                    <div class="d3bRight">
                        <div class="d3rBox">
                            <p class="d3r1">以下名词是您备案过程中经常会遇到的</p>
                            <dl>
                                <dt class="d3Dt" v-for="item in 6" :key="item">
                                    <p class="d3d1">主体{{ item }}</p>
                                    <p class="d3d2">办理备案的个人或者单位均称为备案主体</p>
                                </dt>
                            </dl>
                        </div>
                    </div>
                </div>
                <div class="d3bBox" v-show="showD3 === 2">
                    <div class="d3bLeft">
                        <ul>
                            <li
                                class="d3lLi"
                                v-for="item in 5"
                                :key="item"
                                :class="{ d3lActive: isActive2 === item }"
                                @click="d3lA(item)"
                            >
                                <p class="d3lp">备案场景说明</p>
                            </li>
                        </ul>
                    </div>
                    <div class="d3bRight">
                        <div class="d3rBox">
                            <p class="d3r1">我（备案主体）未在威有云备案</p>
                            <dl>
                                <dt class="d3Dt" v-for="item in 6" :key="item">
                                    <p class="d3d1">首次备案{{ item }}</p>
                                    <p class="d3d2">
                                        如果您的网站托管在威有云中国大陆地区云服务器，且网站的主办者和域名从未办理过备案，
                                        在开通服务前，需在威有云备案系统进行首次备案。
                                    </p>
                                </dt>
                            </dl>
                        </div>
                    </div>
                </div>
                <div class="d3bBox" v-show="showD3 === 3">
                    <div class="d3bLeft">
                        <ul>
                            <li
                                class="d3lLi"
                                v-for="item in 5"
                                :key="item"
                                :class="{ d3lActive: isActive2 === item }"
                                @click="d3lA(item)"
                            >
                                <p class="d3lp">域名注册</p>
                            </li>
                        </ul>
                    </div>
                    <div class="d3bRight">
                        <div class="d3rBox">
                            <p class="d3r1">域名注册——注册更多域名，备案更多网站</p>
                            <div class="d3rImg">
                                <img
                                    src="https://qcloudimg.tencent-cloud.cn/trisys/assets/product/images/cobJinjxlFfJAnyz3QCfq.png"
                                    alt=""
                                    class="diImg"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DmRecord",
    data() {
        return {
            isActive: 1,
            isActive2: 1,
            showD3: 1,
        };
    },
    creatde() {
        this.isActive = 1;
        this.isActive2 = 1;
    },
    methods: {
        d3tA(i) {
            this.isActive2 = 1;
            this.isActive = i;
            this.showD3 = i;
        },
        d3lA(i) {
            this.isActive2 = i;
        },
    },
};
</script>

<style lang="less" scoped>
.dmRecord {
    width: 100%;
    margin: 0 auto;
    margin-top: 87px;
    .dmrBox1 {
        max-width: 1920px;
        height: 720px;
        margin: 0 auto;
        .dmrTop {
            width: 100%;
            height: 480px;
            overflow: hidden;
            position: relative;
            .dtBg {
                width: 100%;
            }
            .dtT {
                width: 50%;
                height: 208px;
                position: absolute;
                top: 130px;
                left: 80px;
                .dt1 {
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    font-size: 40px;
                }
                .dt2 {
                    width: 100%;
                    height: 56px;
                    font-size: 16px;
                    color: gray;
                    margin-top: 20px;
                }
                .dt3 {
                    width: 100%;
                    height: 45px;
                    margin-top: 30px;
                    .dt3B,
                    .dt3B2 {
                        width: 136px;
                        height: 45px;
                        line-height: 45px;
                        text-align: center;
                        border-radius: 5px;
                        background-color: #0052d9;
                        color: white;
                        font-size: 16px;
                        float: left;
                        border-radius: 5px;
                    }
                    .dt3B2 {
                        background-color: white;
                        color: black;
                        margin-left: 15px;
                    }
                }
            }
        }
        .dmrBottom {
            width: 100%;
            height: 240px;
            background-color: #f3f5f8;
            border-top: 2px solid #fff;
            .db1 {
                max-width: 1200px;
                height: 120px;
                margin: 0 auto;
                position: relative;
                .db1Video {
                    position: absolute;
                    left: 10px;
                    top: -70px;
                    box-sizing: border-box;
                    width: 314px;
                    background-image: linear-gradient(0deg, #fff, #f3f5f8);
                    border: 2px solid #fff;
                    box-shadow: 8px 8px 20px 0 rgb(55 99 170 / 10%);
                    border-radius: 4px;
                    padding: 6px;
                    cursor: pointer;
                    transition: all 0.3s linear;
                    video {
                        width: 100%;
                        border-radius: 4px;
                    }
                }
                .db1Ts {
                    width: 850px;
                    height: 120px;
                    float: right;
                    ul {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: space-around;
                        .dtLi {
                            width: 33.333%;
                            height: 100%;
                            text-align: center;
                            transition: 0.5s;
                            .dtl1 {
                                width: 80%;
                                height: 25px;
                                line-height: 25px;
                                font-size: 18px;
                                margin: 0 auto;
                                margin-top: 20px;
                            }
                            .dtl2 {
                                width: 80%;
                                height: 20px;
                                line-height: 20px;
                                font-size: 14px;
                                color: gray;
                                margin-top: 10px;
                                margin: 0 auto;
                                margin-top: 10px;
                            }
                        }
                        .dtLi:hover {
                            background-color: white;
                            .dtl1 {
                                color: #0052d9;
                            }
                        }
                    }
                }
            }
            .db2 {
                width: 1190px;
                height: 68px;
                background-image: linear-gradient(0deg, #fff, #f3f5f8);
                border: 2px solid #fff;
                box-shadow: 8px 8px 20px 0 rgb(55 99 170 / 10%);
                border-radius: 4px;
                margin: 0 auto;
                margin-top: 20px;
                .db2t {
                    width: 50%;
                    height: 25px;
                    line-height: 25px;
                    float: left;
                    margin-top: 22px;
                    .d21I {
                        width: 62px;
                        height: 20px;
                        line-height: 20px;
                        text-align: center;
                        border-radius: 5px;
                        border: 1px solid #0052d9;
                        color: #0052d9;
                        font-size: 12px;
                        margin-left: 20px;
                        float: left;
                    }
                    .d21P {
                        width: 370px;
                        height: 25px;
                        line-height: 25px;
                        font-size: 14px;
                        color: gray;
                        margin-left: 20px;
                        float: left;
                    }
                }
            }
        }
    }
    .dmrBox2 {
        width: 100%;
        height: 350px;
        .db2Box {
            width: 1280px;
            height: 100%;
            margin: 0 auto;
            overflow: hidden;
            .d2bTop {
                width: 100%;
                height: 50px;
                font-size: 28px;
                margin-top: 20px;
                text-align: center;
            }
            .d2bList {
                width: 100%;
                height: 145px;
                margin-top: 40px;
                position: relative;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    .d2lLi {
                        width: 16%;
                        height: 100%;
                        z-index: 5;
                        .d2l1 {
                            width: 60px;
                            height: 60px;
                            border-radius: 50%;
                            font-size: 24px;
                            line-height: 60px;
                            text-align: center;
                            background: linear-gradient(0deg, #fff, #f3f5f8);
                            border: 2px solid white;
                            box-shadow: 1px 1px 10px 1px gainsboro;
                            margin: 0 auto;
                        }
                        .d2l2 {
                            width: 100%;
                            height: 25px;
                            line-height: 25px;
                            text-align: center;
                            margin-top: 15px;
                        }
                        .d2l3 {
                            width: 100%;
                            height: 20px;
                            line-height: 20px;
                            text-align: center;
                            color: #0052d9;
                            margin-top: 20px;
                            font-size: 14px;
                            .iconfont {
                                font-size: 14px;
                            }
                        }
                    }
                }
                .d2x {
                    width: 80%;
                    height: 1px;
                    position: absolute;
                    top: 30px;
                    left: 120px;
                    background: url("https://cloudcache.tencent-cloud.com/open_proj/proj_qcloud_v2/tea-portal-material-portal/build/Main/images/tpm1-step-line_4f6.png")
                        repeat-x;
                }
            }
        }
    }
    .dmrBox3 {
        width: 100%;
        height: 755px;
        overflow: hidden;
        .db3Top {
            width: 40%;
            height: 120px;
            margin: 0 auto;
            margin-top: 50px;
            .d3tp {
                width: 100%;
                height: 40px;
                line-height: 40px;
                text-align: center;
                font-size: 28px;
            }
            .d3tTab {
                width: 100%;
                height: 45px;
                margin-top: 30px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    .d3tLi {
                        width: 33%;
                        height: 100%;
                        font-size: 16px;
                        text-align: center;
                        cursor: pointer;
                    }
                    .d3tActive {
                        border-bottom: 5px solid #0052d9;
                        color: #0052d9;
                    }
                }
            }
        }
        .db3Bottom {
            width: 100%;
            height: 570px;
            background: #f3f5f8;
            box-shadow: inset 8px 8px 20px 0 #3763aa1c, inset -8px -8px 20px 0 #fff;
            overflow: hidden;
            .d3bBox {
                max-width: 1280px;
                height: 490px;
                margin: 0 auto;
                margin-top: 40px;
                border-radius: 10px;
                .d3bLeft {
                    width: 17%;
                    height: 100%;
                    float: left;
                    ul {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        .d3lLi {
                            width: 85%;
                            height: 52px;
                            background: #f3f5f8;
                            box-shadow: inset 8px 8px 20px 0 #3763aa1c, inset -8px -8px 20px 0 #fff;
                            border: 2px solid transparent;
                            transition: background 0.3s linear;
                            border-radius: 5px;
                            margin-top: 25px;
                            cursor: pointer;
                            // transition: 0.3s;
                            .d3lp {
                                width: 100%;
                                height: 20px;
                                line-height: 20px;
                                font-size: 16px;
                                text-align: center;
                                margin-top: 15px;
                            }
                        }
                        .d3lLi:nth-child(1) {
                            margin-top: 0;
                        }
                        .d3lActive {
                            width: 100%;
                            z-index: 5;
                            background-image: linear-gradient(0deg, #f4f6f9, #f3f5f8);
                            border: 2px solid #fff;
                            border-right: none;
                            box-shadow: 8px 8px 20px 0 #3763aa1a, -8px -8px 20px 0 #fff;
                            border-radius: 0;
                        }
                    }
                }
                .d3bRight {
                    width: 80%;
                    height: 100%;
                    float: left;
                    box-sizing: border-box;
                    background-image: linear-gradient(0deg, #fff, #f3f5f8);
                    border: 2px solid #fff;
                    box-shadow: 8px 8px 20px 0 #3763aa1a, -8px -8px 20px 0 #fff;
                    .d3rBox {
                        width: 90%;
                        height: 90%;
                        margin: 0 auto;
                        margin-top: 25px;
                        .d3r1 {
                            width: 100%;
                            height: 25px;
                            line-height: 25px;
                            font-size: 16px;
                        }
                        .d3rImg {
                            width: 100%;
                            height: 250px;
                            margin-top: 30px;
                            .diImg {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        dl {
                            width: 100%;
                            height: 400px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-around;
                            .d3Dt {
                                width: 100%;
                                min-height: 50px;
                                .d3d1 {
                                    width: 100%;
                                    height: 20px;
                                    line-height: 20px;
                                    font-size: 14px;
                                    color: gray;
                                }
                                .d3d2 {
                                    width: 100%;
                                    min-height: 25px;
                                    line-height: 25px;
                                    font-size: 14px;
                                    color: gray;
                                    margin-top: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
